import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import TwoColumn from '../components/TwoColumn'
import IconsGrid from '../components/IconsGrid'
import CustomerResults from '../components/CustomerResults'
import { BorderCurvesTop } from '../components/BorderCurves'

export const ServicesPageTemplate = ({
  title,
  metatitle,
  metadescription,
  heading,
  twocolumn,
  mainpitch,
  customerresults,   
  iconsgrid,
  helmet,
}) => (
  <div className="services-pg">    
    {helmet || ''}  
    <section className="section section--gradient">
      <div className="container">
        <div className="section" style={{paddingTop: '0'}}>
          <div className="columns">
            <div className="column is-12">
              <div className="content hm-page">  
                <div className="container">
                  <div className="hero-text"
                    > 
                    <nav className="breadcrumb" aria-label="breadcrumbs">
                      <ul>
                        <li><a href="/">Human</a></li>
                        <li><span>Services</span></li>
                      </ul>
                    </nav> 
                    <h1
                      className="has-text-weight-bold">   
                      {heading}
                    </h1>                    
                  </div>    
                </div>                      
                <div className="columns casestudy-section">
                  <div className="column is-10 is-offset-1 two-column-reverse">
                    <TwoColumn gridItems={twocolumn} />
                  </div>
                </div>    
                <div className="hm-full-width how-we-do" style={{
                        backgroundImage: `url('/img/services-main-pitch-bg.png')`,
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`,                        
                  }}>
                  <div className="half-circle" 
                  style={{
                        backgroundImage: `url('/img/services-half-circle.png')`,
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`,
                        height: '200px',
                        width: '100px',
                        position: 'absolute',
                  }}
                  >
                      
                  </div>
                  <div className="columns">
                    <div className="column is-10 is-offset-1 is-half-tablet">
                      <div className="columns">
                        <div className="column is-5">
                          <h2>{mainpitch.title}</h2>
                          <div className="how-we-do__text"
                            dangerouslySetInnerHTML={{
                              __html: mainpitch.description,
                            }}
                          />
                        </div>
                        <div className="column is-4 is-offset-1 is-half-tablet">
                          <h2>{mainpitch.listitemtitle}</h2>
                          <ul>
                            {mainpitch.listitems.map(item => (
                              <li key={item} className="is-size-5">
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      </div>
                  </div>  
                </div>                                                             
                <div className="hm-full-width  intro-section-wrapper" style={{
                        backgroundImage: `url('/img/services-customers.png')`,
                        backgroundRepeat: `no-repeat`,
                        backgroundSize: `cover`,                        
                  }}>
                  <BorderCurvesTop />
                  <div className="container">
                    <div className="column customer-results">
                      <div className="columns">
                        <div className="column is-12 is-half-tablet">
                          <div className="columns">
                            <div className="column is-5 is-offset-1">
                              <div className="customer-results-heading"><h2 className="has-text-weight-semibold ">
                                {customerresults.heading}
                              </h2></div>                             
                            </div>
                            <div className="column is-6">
                              <CustomerResults gridItems={customerresults.results} />
                            </div>
                          </div>
                        </div>                        
                      </div>                                            
                    </div>                  
                  </div>                  
                </div> 
                <div className="intro-section-wrapper icons-grid" >                  
                  <div className="column" >                      
                      <IconsGrid gridItems={iconsgrid} />
                  </div>                  
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

ServicesPageTemplate.propTypes = {  
  title: PropTypes.string,
  metatitle: PropTypes.string,
  metadescription: PropTypes.string,
  heading: PropTypes.string,  
  twocolumn: PropTypes.array,
  mainpitch: PropTypes.object,  
  iconsgrid: PropTypes.shape({
    heading:  PropTypes.string,
    icons: PropTypes.array,
  }),
  customerresults: PropTypes.shape({
    heading:  PropTypes.string,
    results: PropTypes.array,
  }),
  helmet: PropTypes.object,
}

const ServicesPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ServicesPageTemplate        
        title={post.frontmatter.title}
        metatitle={post.frontmatter.metatitle}      
        metadescription={post.frontmatter.metadescription}
        heading={post.frontmatter.heading}        
        twocolumn={post.frontmatter.twocolumn}
        mainpitch={post.frontmatter.mainpitch}        
        iconsgrid={post.frontmatter.iconsgrid}                   
        customerresults={post.frontmatter.customerresults}    
        helmet={
          <Helmet titleTemplate="%s | Human">
            <title>{`${post.frontmatter.metatitle}`}</title> 
            <meta name="description" content={`${post.frontmatter.metadescription}`} />          
          </Helmet>
        }      
      />
    </Layout>
  )
}

ServicesPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ServicesPage

export const pageQuery = graphql`
  query ServicesPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { templateKey: { eq: "services-page" } }) {
      id
      frontmatter {
        title
        metatitle
        metadescription
        heading                
        twocolumn {
          header
          image {
            childImageSharp {
              fluid(maxWidth: 350, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          text          
        }
        mainpitch {
          title
          description 
          listitemtitle
          listitems
        }        
        iconsgrid {
          heading
          icons {
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            text
          }
        }
        customerresults {
          heading
          results {
            image {
              childImageSharp {
                fluid(maxWidth: 100, quality: 64) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            amount
            text
          }
        }        
      }
    }
  }
`
